/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

,
{
  path: "/icons",
  name: "Icons",
  icon: "ni ni-planet text-blue",
  component: Icons,
  layout: "/admin"
},
{
  path: "/maps",
  name: "Maps",
  icon: "ni ni-pin-3 text-orange",
  component: Maps,
  layout: "/admin"
},
{
  path: "/user-profile",
  name: "User Profile",
  icon: "ni ni-single-02 text-yellow",
  component: Profile,
  layout: "/admin"
},
{
  path: "/tables",
  name: "Tables",
  icon: "ni ni-bullet-list-67 text-red",
  component: Tables,
  layout: "/admin"
},
{
  path: "/login",
  name: "Login",
  icon: "ni ni-key-25 text-info",
  component: Login,
  layout: "/auth"
},
{
  path: "/register",
  name: "Register",
  icon: "ni ni-circle-08 text-pink",
  component: Register,
  layout: "/auth"
}

*/
import Index from "views/Index.js";
//import Profile from "views/examples/Profile.js";
//import Maps from "views/examples/Maps.js";
//import Register from "views/examples/Register.js";
//import Login from "views/examples/Login.js";
//import Tables from "views/examples/Tables.js";
//import Icons from "views/examples/Icons.js";
import Polls from "views/Polls.jsx";
//import Splits from "views/Splits.jsx";
//import Soon from "views/Soon.jsx";

var routes = [
  {
    path: "/welcome",
    name: "Welcome",
    icon: "fas fa-desktop text-primary",
    component: Index,
    layout: "/admin"
  },
  {
    path: "/polls",
    name: "Create and Update Polls",
    icon: "fas fa-poll text-primary",
    component: Polls,
    layout: "/admin"
  }
];
/*var routesBak = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fas fa-desktop text-primary",
    component: Index,
    layout: "/admin"
  },
  {
    path: "/polls",
    name: "Create and Update Polls",
    icon: "fas fa-poll text-primary",
    component: Polls,
    layout: "/admin"
  },
  {
    path: "/splits",
    name: "Create and Mangage Split Tests",
    icon: "fas fa-flask text-primary",
    component: Soon,
    layout: "/admin"
  },
  {
    path: "/analytics",
    name: "Analytics",
    icon: "fas fa-chart-line text-primary",
    component: Soon,
    layout: "/admin"
  },
  {
    path: "/login",
    name: "Login",
    icon: "fas fa-sign-in-alt text-primary",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/settings",
    name: "Settings",
    icon: "fas fa-cog text-primary",
    component: Soon,
    layout: "/admin"
  }
];*/
export default routes;
