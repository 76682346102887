import React, {Component} from "react";
import { gql } from "apollo-boost";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import { Button, Collapse, FormGroup, Label, Input, Card, CardHeader, CardBody, Spinner } from 'reactstrap';
import FileInputComponent from 'react-file-input-previews-base64';

const updatePollMutation = gql`
  mutation (
    $id: String!,
    $name: String!,
    $domain: String,
    $displayName: String,
    $theme: String,
    $privacyPolicy: String,
    $pollToolID: String,
    $websiteName: String,
    $landTopText: String,
    $landHeadline: String,
    $landSubhead: String,
    $landYes: String,
    $landNo: String,
    $landSocialBefore: String,
    $landSocialAfter: String,
    $emailHead: String,
    $emailButton: String,
    $emailSubHead: String,
    $emailSLAPI: String,
    $emailSLHash: String,
    $emailSLList: String,
    $loadHeadline: String,
    $loadSubhead: String,
    $loadWaitMessage: String,
    $loadOfferHeadline: String,
    $loadOfferButton: String,
    $loadOfferLink: String
    $redirectTime: Int
  ){
    updatePoll (
      id: $id,
      name: $name,
      domain: $domain,
      displayName: $displayName,
      theme: $theme,
      privacyPolicy: $privacyPolicy,
      pollToolID: $pollToolID,
      websiteName: $websiteName,
      landTopText: $landTopText,
      landHeadline: $landHeadline,
      landSubhead: $landSubhead,
      landYes: $landYes,
      landNo: $landNo,
      landSocialBefore: $landSocialBefore,
      landSocialAfter: $landSocialAfter,
      emailHead: $emailHead,
      emailButton: $emailButton,
      emailSubHead: $emailSubHead,
      emailSLAPI: $emailSLAPI,
      emailSLHash: $emailSLHash,
      emailSLList: $emailSLList,
      loadHeadline: $loadHeadline,
      loadSubhead: $loadSubhead,
      loadWaitMessage: $loadWaitMessage,
      loadOfferHeadline: $loadOfferHeadline,
      loadOfferButton: $loadOfferButton,
      loadOfferLink: $loadOfferLink,
      redirectTime: $redirectTime
    ){
      timestamp
    }
  }
`

const updatePollImagesMutation = gql`
  mutation (
    $id: String!,
    $landImage: String,
    $loadImage: String,
  ){
    updatePoll (
      id: $id,
      landImage: $landImage,
      loadImage: $loadImage,
    ){
      timestamp
    }
  }
`

class PollOptions extends Component {
  constructor(props){
    super(props);
    this.state = {
      general: true,
      landing: false,
      email: false,
      loading: false,
      name: '',
      domain: '',
      displayName: '',
      theme: '',
      privacyPolicy: '',
      pollToolID: '',
      websiteName: '',
      landTopText: '',
      landHeadline: '',
      landSubhead: '',
      landYes: '',
      landNo: '',
      landSocialBefore: '',
      landSocialAfter: '',
      emailHead: '',
      emailButton: '',
      emailSubHead: '',
      emailSLAPI: '',
      emailSLHash: '',
      emailSLList: '',
      loadHeadline: '',
      loadSubhead: '',
      loadWaitMessage: '',
      loadOfferHeadline: '',
      loadOfferButton: '',
      loadOfferLink: '',
      redirectTime: ''
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChangeEncrypted = this.handleInputChangeEncrypted.bind(this);
    this.setTheme = this.setTheme.bind(this);
    this.setImage = this.setImage.bind(this);
    this.updateState = this.updateState.bind(this);
    this.updateData = this.updateData.bind(this);
    this.createOption = this.createOption.bind(this);
    this.createOptionEncrypted = this.createOptionEncrypted.bind(this);
    this.toggleOptions = this.toggleOptions.bind(this);
  }
  componentDidUpdate(prevState, prevProps) {
    // Typical usage (don't forget to compare props):
    //console.log('did update');
    //console.log(this.props);
    //console.log(prevProps);
    if (this.state !== prevState && this.props.id === this.state.id) {
      //console.log('ID at update: ', this.state.id);
      //console.log(this.props);
      //console.log(prevProps);
      //console.log(this.state);
      //console.log(prevState);
      //console.log('Update Verificaiton');
      //setTimeout(this.updateData, 50);
      this.updateData();
    }

    if (this.props.id !== prevProps.id) {
      /*if (this.props.id !== prevProps.id || this.props.landing !== prevProps.landing || this.props.email !== prevProps.email || this.props.loading !== prevProps.loading) {*/
      console.log('getting state from props');
      this.setState({ ...this.props });
    }
  }
  updateData(){
    console.log("In Update: ",this.state.name);
    //console.log ('State to be pushed to DB:');
    //console.log (this.state);
    if(this.state.name !== undefined && this.state.name !== "") {
      this.props.updatePollMutation({
        variables: {
          id: this.state.id,
          name: this.state.name,
          domain: this.state.domain,
          displayName: this.state.displayName,
          theme: this.state.theme,
          privacyPolicy: this.state.privacyPolicy,
          pollToolID: this.state.pollToolID,
          websiteName: this.state.websiteName,
          landTopText: this.state.landTopText,
          landHeadline: this.state.landHeadline,
          landSubhead: this.state.landSubhead,
          landYes: this.state.landYes,
          landNo: this.state.landNo,
          landSocialBefore: this.state.landSocialBefore,
          landSocialAfter: this.state.landSocialAfter,
          emailHead: this.state.emailHead,
          emailButton: this.state.emailButton,
          emailSubHead: this.state.emailSubHead,
          emailSLAPI: this.state.emailSLAPI,
          emailSLHash: this.state.emailSLHash,
          emailSLList: this.state.emailSLList,
          loadHeadline: this.state.loadHeadline,
          loadSubhead: this.state.loadSubhead,
          loadWaitMessage: this.state.loadWaitMessage,
          loadOfferHeadline: this.state.loadOfferHeadline,
          loadOfferButton: this.state.loadOfferButton,
          loadOfferLink: this.state.loadOfferLink,
          redirectTime: parseInt(this.state.redirectTime, 10)
        }
      });

      this.props.updatePollImagesMutation({
        variables: {
          id: this.state.id,
          landImage: this.state.landImage,
          loadImage: this.state.loadImage
        }
      });
    } else {
      console.log('name undefined');
    }
  }
  updateState() {
    if (this.state.name === '') {
      console.log('Updating State from props');
      this.setState({ ...this.props });
    }
  }
  handleInputChange(event) {
    let target = event.target;
    let value = target.value;
    let name = target.name;

    this.setState({
      [name]: value
    });
    //this.updateData();
  }
  handleInputChangeEncrypted(event) {
    let target = event.target;
    let value = btoa(target.value);
    let name = target.name;

    this.setState({
      [name]: value
    });
    //this.updateData();
  }
  toggleOptions(option) {
    let currentOption = this.state[option];
    //console.log(option);
    this.setState ({
      [option]: !currentOption
    })
  }
  setTheme(event) {
    this.setState({theme: event.target.value});
    //this.updateData();
  }
  setImage(image, target) {
    this.setState({
      [target]: image
    })
    //console.log(image);
  }
  createOption(name, display) {
    let value = this.state[name] ? this.state[name] : '';
    return (
      <FormGroup>
        <Label>{display}</Label>
        <Input
          name={name}
          type="text"
          value={value}
          onChange={this.handleInputChange} />
      </FormGroup>
    );
  }
  createOptionEncrypted(name, display) {
    let value = '';
    if (this.state[name] !== '#') {
      value = this.state[name] ? atob(this.state[name]) : '';
    } else {
      value = this.state[name] ? this.state[name] : '';
    }
    return (
      <FormGroup>
        <Label>{display}</Label>
        <Input
          name={name}
          type="text"
          value={value}
          onChange={this.handleInputChangeEncrypted} />
      </FormGroup>
    );
  }
  render() {
    if(this.props.id === '') {
      return (
        <>
          <Card>
            <CardHeader>
              <h2>Options</h2>
            </CardHeader>
            <CardBody>
              <h2><Spinner color="primary" /> Awaiting Selection...</h2>
            </CardBody>
          </Card>
        </>
      )
    }
    //this.updateState();
    return (
      <>
      <Card>
        <CardHeader>
          <h2>Options</h2>
        </CardHeader>
        <CardBody>
          <Button className="mb-3" onClick={() => this.toggleOptions('general')} active={this.state.general}><span className="h2">{this.state.general ? <i class="fas fa-caret-down"></i> : <i class="fas fa-caret-right"></i>} General:</span></Button>
          <br/>
          <Collapse isOpen={this.state.general}>
            {this.createOption('name','Poll Name:')}
            {this.createOption('domain','Domain:')}
            {this.createOption('displayName','NickName: (use this to tag polls for your reference)')}

            <FormGroup>
              <Label>Theme:</Label>
              <Input type="select" value={this.state.theme} onChange={this.setTheme}>
                <option>cosmo</option>
                <option>cyborg</option>
                <option>darkly</option>
                <option>flatly</option>
                <option>journal</option>
                <option>litera</option>
                <option>lumen</option>
                <option>lux</option>
                <option>materia</option>
                <option>pulse</option>
                <option>sandstone</option>
                <option>simplex</option>
                <option>sketchy</option>
                <option>slate</option>
                <option>solar</option>
                <option>superhero</option>
                <option>united</option>
                <option>yeti</option>
              </Input>
            </FormGroup>
            <Label>
              You can host your own privacy policy page anywhere you would
              like. We suggest{" "}
              <a href="https://privacypolicyonline.com" target="_blank" rel="noopener noreferrer">
              this one</a>. It's free and easy to use. Here is a
              short <a href="#" onClick={() => this.props.toggleVideoHelp('privacyHelp')}>video</a> showing you how to combine it with
              your poll.
            </Label>
            {this.createOption('privacyPolicy','Link to Privacy Policy:')}
            <Label>
            In order to have your poll update dynamically, please use our
            <a href="http://polls.thefasttracks.com/pollBeta/" target="_blank">
            poll tool</a>. Here is a <a href="#" onClick={() => this.props.toggleVideoHelp('pollToolHelp')}>video</a> showing how to use
            this tool with your poll.
            </Label>
            {this.createOption('pollToolID','Your Poll Tool ID:')}
          </Collapse>

          <Button className="mb-3" onClick={() => this.toggleOptions('landing')} active={this.state.landing}><span className="h2">{this.state.landing ? <i class="fas fa-caret-down"></i> : <i class="fas fa-caret-right"></i>} Landing Page:</span></Button>
          <br/>
          <Collapse isOpen={this.state.landing}>
            {this.createOption('landTopText','Top Text:')}
            {this.createOption('landHeadline','Headline:')}
            {this.createOption('landSubhead','Subheadline:')}
            <FormGroup>
              <Label>Image: (JPEG, PNG or GIF up to 4MB)</Label>
              <Label>Need to resize an image? Go <a href="https://photoshop.adobe.com/resize/" target="_blank">here</a>.</Label>
              <FileInputComponent
                labelText=""
                labelStyle={{fontSize:14}}
                imagePreview={false}
                buttonComponent=<Button>Select Image</Button>
                multiple={false}
                callbackFunction={(file_arr)=>{this.setImage(file_arr.base64, "landImage")}}
                accept="image/*"
              />
            </FormGroup>
            {this.createOption('landYes','Yes Button Text:')}
            {this.createOption('landNo','No Button Text:')}
            {this.createOption('landSocialBefore','Social Proof Before Number:')}
            {this.createOption('landSocialAfter','Social Proof After Number:')}
            {this.createOption('websiteName','Website Name:')}
          </Collapse>

          <Button className="mb-3" onClick={() => this.toggleOptions('email')} active={this.state.email}><span className="h2">{this.state.email ? <i class="fas fa-caret-down"></i> : <i class="fas fa-caret-right"></i>} Email:</span></Button>
          <br/>
          <Collapse isOpen={this.state.email}>
            {this.createOption('emailHead','Email Popup Headline:')}
            {this.createOption('emailButton','Email Popup Button:')}
            {this.createOption('emailSubHead','Email Popup Subheadline:')}
            <Label>Need help? Click <a href="#" onClick={() => this.props.toggleVideoHelp('emailHelp')}>here</a> for video instructions.</Label>
            <Label>Enter your Sendlane integration API and API Hash here, plus the number of the list you want subscribers added to.</Label>
            {this.createOptionEncrypted('emailSLAPI','API:')}
            {this.createOptionEncrypted('emailSLHash','API Hash:')}
            {this.createOption('emailSLList','Sendlane List Number:')}
          </Collapse>

          <Button className="mb-3" onClick={() => this.toggleOptions('loading')} active={this.state.loading}><span className="h2">{this.state.loading ? <i class="fas fa-caret-down"></i> : <i class="fas fa-caret-right"></i>} Loading:</span></Button>
          <br/>
          <Collapse isOpen={this.state.loading}>
            {this.createOption('loadHeadline','Headline:')}
            {this.createOption('loadSubhead','Subheadline:')}
            {this.createOption('loadWaitMessage','Wait Message:')}
            {this.createOption('loadOfferHeadline','Offer Headline:')}

            <FormGroup>
            <Label>Image: (JPEG, PNG or GIF up to 4MB)</Label>
            <Label>Need to resize an image? Go <a href="https://photoshop.adobe.com/resize/" target="_blank">here</a>.</Label>
              <FileInputComponent
                labelText=""
                labelStyle={{fontSize:14}}
                imagePreview={false}
                buttonComponent=<Button>Select Image</Button>
                multiple={false}
                callbackFunction={(file_arr)=>{this.setImage(file_arr.base64, "loadImage")}}
                accept="image/*"
              />
            </FormGroup>
            {this.createOption('loadOfferButton','Offer Button:')}
            <Label>
            Your affiliate link should go here. If you are promoting a ClickBank
            offer, this would be your hoplink. Need to build a hoplink? You can
            use our hoplink builder <a href="#">here</a>.
            </Label>
            {this.createOption('loadOfferLink','Link to Offer:')}
            <Label>
            You can automatically redirect visitors after a set number of
            seconds (default 20). If you set this to 0, the redirect will be
            disabled.
            </Label>
            {this.createOption('redirectTime','Seconds Before Redirect:')}
          </Collapse>
        </CardBody>
      </Card>
      </>
    );
  }
}

export default compose(
  graphql(updatePollMutation, {
    name: "updatePollMutation",
    options: (state) => {
      return {
        variables: {
          id: state.id,
          name: state.name,
          domain: state.domain,
          displayName: state.displayName,
          theme: state.theme,
          privacyPolicy: state.privacyPolicy,
          pollToolID: state.pollToolID,
          websiteName: state.websiteName,
          landTopText: state.landTopText,
          landHeadline: state.landHeadline,
          landSubhead: state.landSubhead,
          landImage: state.landImage,
          landYes: state.landYes,
          landNo: state.landNo,
          landSocialBefore: state.landSocialBefore,
          landSocialAfter: state.landSocialAfter,
          emailHead: state.emailHead,
          emailButton: state.emailButton,
          emailSubHead: state.emailSubHead,
          emailSLAPI: state.emailSLAPI,
          emailSLHash: state.emailSLHash,
          emailSLList: state.emailSLList,
          loadHeadline: state.loadHeadline,
          loadSubhead: state.loadSubhead,
          loadWaitMessage: state.loadWaitMessage,
          loadOfferHeadline: state.loadOfferHeadline,
          loadImage: state.loadImage,
          loadOfferButton: state.loadOfferButton,
          loadOfferLink: state.loadOfferLink,
          redirectTime: parseInt(state.redirectTime, 10)
        }
      }
    }
  }),
  graphql(updatePollImagesMutation, {
    name: "updatePollImagesMutation",
    options: (state) => {
      return {
        variables: {
          id: state.id,
          landImage: state.landImage,
          loadImage: state.loadImage
        }
      }
    }
  })
)
(PollOptions);
