/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

import { gql } from "apollo-boost";
import { graphql } from "react-apollo";

const getAnalyticsQuery = gql`
  query($id: String){
    analyticsSum(userId: $id){
      funnels
      splitTests
      visits
      conversions
    }
  }
`

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

class Header extends React.Component {

  componentDidUpdate() {
    if (!this.props.data.loading) {
      console.log(this.props.data.analyticsSum);
      this.props.data.startPolling(500);
    }
  }
  componentDidMount() {
    if (!this.props.data.loading) {
      console.log(this.props.data.analyticsSum);
      this.props.data.startPolling(500);
    }
  }
  render() {
    return (
      <>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              {/*
              <Row>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h4"
                            className="text-uppercase text-muted mb-0"
                          >
                            Funnels
                          </CardTitle>
                          <span className="h1 font-weight-bold mb-0">
                            {!this.props.data.loading && this.props.data.analyticsSum !== undefined ? this.props.data.analyticsSum.funnels : 'Loading...'}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                            <i className="fas fa-funnel-dollar" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm h5">
                        <span className="text-success mr-2">
                          <i className="fa fa-tachometer-alt" />
                        </span>{" "}{!this.props.data.loading && this.props.data.analyticsSum !== undefined ? this.props.data.analyticsSum.funnels : '...'}
                        <span className="text-nowrap"> Of unlimited remaining</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h4"
                            className="text-uppercase text-muted mb-0"
                          >
                            Split Tests
                          </CardTitle>
                          <span className="h1 font-weight-bold mb-0">
                            {!this.props.data.loading && this.props.data.analyticsSum !== undefined ? this.props.data.analyticsSum.splitTests : 'Loading...'}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <i className="fas fa-flask" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm h5">
                        <span className="text-warning mr-2">
                          <i className="fas fa-tachometer-alt" />
                        </span>{" "}{!this.props.data.loading && this.props.data.analyticsSum !== undefined ? this.props.data.analyticsSum.splitTests : '...'}
                        <span className="text-nowrap"> Of unlimited remaining</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h4"
                            className="text-uppercase text-muted mb-0"
                          >
                            Visits
                          </CardTitle>
                          <span className="h1 font-weight-bold mb-0">{!this.props.data.loading && this.props.data.analyticsSum !== undefined ? this.props.data.analyticsSum.visits : 'Loading...'}</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-default text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm h5">
                        <span className="text-info mr-2">
                          <i className="fas fa-calendar" />
                        </span>{" "}
                        <span className="text-nowrap">Over the past 7 days</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h4"
                            className="text-uppercase text-muted mb-0"
                          >
                            Conversions
                          </CardTitle>
                          <span className="h1 font-weight-bold mb-0">
                            {!this.props.data.loading && this.props.data.analyticsSum !== undefined ? this.props.data.analyticsSum.conversions : 'Loading...'}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                            <i className="fas fa-check-circle" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm h5">
                        <span className="text-info mr-2">
                          <i className="fas fa-calendar" />
                        </span>{" "}
                        <span className="text-nowrap">Over the past 7 days</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>*/}
            </div>
          </Container>
        </div>
      </>
    );
  }
}

let id = getCookie('uidm');

console.log("The user ID is: " + id);

export default graphql(getAnalyticsQuery, {

  options: (props) => {
    return {
      variables: {
        id: id
      }
    }
  }
})(Header);
