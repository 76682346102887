/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// video player
import ReactPlayer from 'react-player';
// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Button,
  CardBody,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
// core components
import Header from "components/Headers/Header2.js";

//import UserList from "components/GraphQL/UserList.jsx";
//import AddUser from "components/GraphQL/AddUser.jsx";
//import AddFunnel from "components/GraphQL/AddFunnel.jsx";
import PollList from "components/GraphQL/PollList.jsx";
import AddPoll from "components/GraphQL/AddPoll.jsx";
import PollOptions from "components/GraphQL/PollOptions.jsx";

const liveUrl = "https://ftfunnels.thefasttracks.com/?id=";
//const devUrl = "http://localhost:3001/?id="

class Polls extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      id: '',
      previewMode: 'landing',
      privacyHelp: false,
      pollToolHelp: false,
      emailHelp: false
    };
  }
  selectPoll = (
    name,
    id,
    domain,
    displayName,
    theme,
    privacyPolicy,
    pollToolID,
    websiteName,
    landTopText,
    landHeadline,
    landSubhead,
    landImage,
    landYes,
    landNo,
    landSocialBefore,
    landSocialAfter,
    emailHead,
    emailButton,
    emailSubHead,
    emailSLAPI,
    emailSLHash,
    emailSLList,
    loadHeadline,
    loadSubhead,
    loadWaitMessage,
    loadOfferHeadline,
    loadImage,
    loadOfferButton,
    loadOfferLink,
    redirectTime
  ) => {
    this.setState ({
      name,
      id,
      domain,
      displayName,
      theme,
      privacyPolicy,
      pollToolID,
      websiteName,
      landTopText,
      landHeadline,
      landSubhead,
      landImage,
      landYes,
      landNo,
      landSocialBefore,
      landSocialAfter,
      emailHead,
      emailButton,
      emailSubHead,
      emailSLAPI,
      emailSLHash,
      emailSLList,
      loadHeadline,
      loadSubhead,
      loadWaitMessage,
      loadOfferHeadline,
      loadImage,
      loadOfferButton,
      loadOfferLink,
      redirectTime
    });
    this.disableScroll = this.disableScroll.bind(this);
    this.enableScroll = this.enableScroll.bind(this);
    this.getCookie = this.getCookie.bind(this);
  };

  componentDidUpdate(prevProps) {
    //this.enableScroll();
    setTimeout(this.enableScroll, 500);
  }

  setTheme(event) {
    this.setState({theme: event.target.value});
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  disableScroll() {
    // Get the current page scroll position
    let scrollTop = window.pageYOffset;
    let scrollLeft = window.pageXOffset;

    // if any scroll is attempted, set this to the previous value
    window.onscroll = function() {
      window.scrollTo(scrollLeft, scrollTop);
    };
  }

  enableScroll() {
    window.onscroll = function() {};
  }

  displayPreview() {
    console.log(this.state.selectedPoll);
    if (this.state.id === '') {
      return (
        <h2><Spinner color="primary" /> Awaiting Selection...</h2>
      );} else {
        return (
          <div style={{position: "relative", overflow: "hidden", paddingTop: "120%"}}>
            <iframe
              title="Funnel Preview"
              src={liveUrl + this.state.id + "&dash=true&mode=" + this.state.previewMode}
              style={{width: "100%", height: "100%", position: "absolute", top: 0, left: 0, border: 0}}/>
          </div>
        );
      }
    }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  setPreviewMode(mode) {
    this.disableScroll();
    this.setState({
      previewMode: mode
    })
  }

  toggleVideoHelp = (video) => {
    console.log('show/hide privacy help');
    this.setState({
      [video]: !this.state[video]
    })
  }

  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--9" fluid>
        {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Polls</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Poll Name</th>
                      <th scope="col">Domain</th>
                      <th scope="col">Tag</th>
                      <th scope="col">Link</th>
                      <th scope="col">Preview Edit Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    <PollList selectPoll={this.selectPoll}/>

                  </tbody>
                </Table>
                <CardFooter className="py-4 text-right">
                  <AddPoll/>
                </CardFooter>
              </Card>
            </div>
          </Row>
          <Row className="mt-4 row-eq-height">
            <Col md="3">
              <PollOptions
                id={this.state.id}
                name={this.state.name}
                domain={this.state.domain}
                displayName={this.state.displayName}
                theme={this.state.theme}
                websiteName={this.state.websiteName}
                privacyPolicy={this.state.privacyPolicy}
                pollToolID={this.state.pollToolID}
                offerTarget={this.state.offerTarget}
                landTopText={this.state.landTopText}
                landHeadline={this.state.landHeadline}
                landSubhead={this.state.landSubhead}
                landImage={this.state.landImage}
                landYes={this.state.landYes}
                landNo={this.state.landNo}
                landSocialBefore={this.state.landSocialBefore}
                landSocialAfter={this.state.landSocialAfter}
                emailHead={this.state.emailHead}
                emailButton={this.state.emailButton}
                emailSubHead={this.state.emailSubHead}
                emailSLAPI={this.state.emailSLAPI}
                emailSLHash={this.state.emailSLHash}
                emailSLList={this.state.emailSLList}
                loadHeadline={this.state.loadHeadline}
                loadSubhead={this.state.loadSubhead}
                loadWaitMessage={this.state.loadWaitMessage}
                loadOfferHeadline={this.state.loadOfferHeadline}
                loadImage={this.state.loadImage}
                loadOfferButton={this.state.loadOfferButton}
                loadOfferLink={this.state.loadOfferLink}
                redirectTime={this.state.redirectTime}
                loading={false}
                landing={false}
                email={false}
                toggleVideoHelp={this.toggleVideoHelp}
              />
              {/*
              <Card>
                <CardHeader>
                  <span className="h2">Users</span><Button size="sm" color="primary" id="users" style={{float: "right"}}>Show/Hide</Button>
                </CardHeader>
                <CardBody>
                  <UncontrolledCollapse toggler="#users">
                    <UserList />
                    <AddUser />
                  </UncontrolledCollapse>
                </CardBody>
              </Card>
              <Card className="mt-4">
                <CardHeader>
                  <span className="h2">Funnels</span><Button size="sm" color="primary" id="funnels" style={{float: "right"}}>Show/Hide</Button>
                </CardHeader>
                <CardBody>
                  <UncontrolledCollapse toggler="#funnels">
                    <FunnelList funnelCallback={this.selectFunnel}/>
                    <AddFunnel />
                  </UncontrolledCollapse>
                </CardBody>
              </Card>
              */}
            </Col>
            <Col md="9">
              <Card>
                <CardHeader>
                  <h2>Preview Your Poll</h2>
                  <Button onClick={() => this.setPreviewMode('landing')} active={this.state.previewMode === 'landing'}><span className="h3">Landing</span></Button>
                  <Button onClick={() => this.setPreviewMode('email')} active={this.state.previewMode === 'email'}><span className="h3">Email</span></Button>
                  <Button onClick={() => this.setPreviewMode('loading')} active={this.state.previewMode === 'loading'}><span className="h3">Loading</span></Button>
                </CardHeader>
                <CardBody>
                  {this.displayPreview()}
                </CardBody>
              </Card>
            </Col>
          </Row>
          { this.state.privacyHelp ?
            <Modal isOpen={this.state.privacyHelp} toggle={() => this.toggleVideoHelp('privacyHelp')} size="lg">
              <ModalHeader toggle={() => this.toggleVideoHelp('privacyHelp')}>Privacy Policy Video Help</ModalHeader>
              <ModalBody>
                <div className='player-wrapper'>
                  <ReactPlayer
                    className='react-player'
                    url='https://merlinholmes.wistia.com/medias/feh71z0ady'
                    width='100%'
                    height='100%'
                  />
                </div>
              </ModalBody>
            </Modal> : null }
            { this.state.pollToolHelp ?
              <Modal isOpen={this.state.pollToolHelp} toggle={() => this.toggleVideoHelp('pollToolHelp')} size="lg">
                <ModalHeader toggle={() => this.toggleVideoHelp('pollToolHelp')}>Poll Tool Video Help</ModalHeader>
                <ModalBody>
                  <div className='player-wrapper'>
                    <ReactPlayer
                      className='react-player'
                      url='https://merlinholmes.wistia.com/medias/dqf02baip3'
                      width='100%'
                      height='100%'
                    />
                  </div>
                </ModalBody>
              </Modal> : null }
              { this.state.emailHelp ?
                <Modal isOpen={this.state.emailHelp} toggle={() => this.toggleVideoHelp('emailHelp')} size="lg">
                  <ModalHeader toggle={() => this.toggleVideoHelp('emailHelp')}>Email Settings Video Help</ModalHeader>
                  <ModalBody>
                    <div className='player-wrapper'>
                      <ReactPlayer
                        className='react-player'
                        url='https://merlinholmes.wistia.com/medias/r5b89mq1d8'
                        width='100%'
                        height='100%'
                      />
                    </div>
                  </ModalBody>
                </Modal> : null }
        </Container>
      </>
    );
  }
}

export default Polls;
