/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// video player
import ReactPlayer from 'react-player';
// node.js library that concatenates classes (strings)
// import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
//import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions
} from "variables/charts.js";

import Header from "components/Headers/Header.js";
//import UserList from "components/GraphQL/UserList.jsx";
//import AddUser from "components/GraphQL/AddUser.jsx";
//import AddFunnel from "components/GraphQL/AddFunnel.jsx";
//import FunnelList from "components/GraphQL/FunnelList.jsx";

//GraphQL
import { gql } from "apollo-boost";
import { graphql } from "react-apollo";
import { compose } from "recompose";
//GraphQL

/*
query($key: String) {
  cluster (key: $key){
    contigs {
      tax_kingdom
      tax_phylum
      tax_class
      tax_order
      tax_family
      tax_genus
      tax_species
      empo_1
      empo_2
      empo_3
    }
  }
}
`
*/

const getUserQuery = gql`
  query ($kajabiID: String){
    user (kajabiID: $kajabiID){
      userName
      id
    }
  }
`

const addUserMutation = gql`
  mutation ($userName: String!, $kajabiID: String!){
    addUser (userName: $userName, kajabiID: $kajabiID){
      userName
      kajabiID
    }
  }
`

class Index extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      activeNav: 1,
      chartExample1Data: "data1",
      selectedFunnel: ''
    };
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
    this.getCookie = this.getCookie.bind(this);
    this.setCookie = this.setCookie.bind(this);
    this.addUser = this.addUser.bind(this);
  }
  componentDidMount() {
    /*let uid = this.getCookie('uid');
    console.log ("User ID: " + uid);
    if (uid === "undefined") {
      alert ("No valid user session detected, please enter from members area");
      window.location.assign("https://members.thefasttracks.com");
    }*/
  }
  componentDidUpdate(prevProps) {
    //force reload?
    let uid = getCookie('uid');
    let uidm = getCookie('uidm');
    if (this.props.data.user === null && uid !== "") {
      this.addUser();
    }
    if (uidm !== "undefined") {
      console.log("added mongo id");
      this.setCookie("uidm", this.props.data.user.id, 1);
    }
  }
  addUser(){
    console.log("adding user");
    this.props.addUserMutation({
      variables: {
        userName: "Fast Tracks Member",
        kajabiID: getCookie('uid')
      },
      refetchQueries: [{query: getUserQuery}]
    });
  }
  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
  };
  selectFunnel = (funnelId) => {
    this.setState ({
      selectedFunnel: funnelId
    });
  };
  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/*<Row>
            <Col md="3">
              <Card>
                <CardHeader>
                  <span className="h2">Users</span><Button size="sm" color="primary" id="users" style={{float: "right"}}>Show/Hide</Button>
                </CardHeader>
                <CardBody>
                  <UncontrolledCollapse toggler="#users">
                    <UserList />
                    <AddUser />
                  </UncontrolledCollapse>
                </CardBody>
              </Card>
              <Card className="mt-4">
                <CardHeader>
                  <span className="h2">Funnels</span><Button size="sm" color="primary" id="funnels" style={{float: "right"}}>Show/Hide</Button>
                </CardHeader>
                <CardBody>
                  <UncontrolledCollapse toggler="#funnels">
                    <FunnelList funnelCallback={this.selectFunnel}/>
                    <AddFunnel />
                  </UncontrolledCollapse>
                </CardBody>
              </Card>
            </Col>
            <Col md="9">
              <Card>
                <CardHeader>
                  <h2>Preview</h2>
                </CardHeader>
                <CardBody>
                  <iframe src="http://localhost:3001/?dash=true" style={{width: "100%", height: 1200}}/>
                </CardBody>
              </Card>
            </Col>
          </Row>*/}

          <Row>
            <Col md="12">
            <Card>
              <CardHeader>
                <span className="h2">Welcome to Fast Funnels!</span>
              </CardHeader>
              <CardBody>
                <div className='player-wrapper'>
                  <ReactPlayer
                    className='react-player'
                    url='https://merlinholmes.wistia.com/medias/ilnvvp0kbb'
                    width='100%'
                    height='100%'
                    controls='true'
                  />
                </div>
                <br/>
                Welcome to Fast Track Funnels! If this is your first time here, please watch the video above for a quick overview of how everything works.
                <br/><br/>
                <span className="h2">Downloads</span>
                <br/><br/>
                Additional resources are available for download here.
                <br/><br/>
                <span className="h3">QuickStart Guide (coming soon)</span>
              </CardBody>
            </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default compose(
  graphql (getUserQuery, {
    options: (props) => {
      return {
        variables: {
          kajabiID: getCookie('uid')
        }
      }
    }
  }),
  graphql(addUserMutation, {name: "addUserMutation"})
)
(Index);
