/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

* Adapted for Precision Media Lab LLC by Matthew Goff

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";

// apollo client setup - detect dev mode and adjust server URI as needed
let serverLocation = '';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev mode
    serverLocation = "http://localhost:4000/graphql";
    serverLocation = "https://funnels.thefasttracks.com/graphql";
} else {
    // production mode
    serverLocation = "https://funnels.thefasttracks.com/graphql";
}

const client = new ApolloClient({
  uri: serverLocation
})

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

let uid = getCookie('uid');
console.log('user id: ' + uid);
if (uid === "undefined") {
  alert ("No valid user session detected, please enter from members area");
  window.location.assign("https://members.thefasttracks.com");
} else {
  ReactDOM.render(
    <BrowserRouter>
      <ApolloProvider client={client}>
        <Switch>
          <Route path="/admin" render={props => <AdminLayout {...props} />} />
          <Route path="/auth" render={props => <AuthLayout {...props} />} />
          <Redirect from="/" to="/admin/welcome" />
        </Switch>
      </ApolloProvider>
    </BrowserRouter>,
    document.getElementById("root")
  );
}
