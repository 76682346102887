import React, {Component} from "react";
import { gql } from "apollo-boost";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import { Button, Form } from 'reactstrap';

const addPollMutation = gql`
  mutation ($userId: String!){
    addPoll (userId: $userId){
      name
      id
    }
  }
`

class AddPoll extends Component {
  constructor(props){
    super(props);
    this.state = {
      name: '',
      pollId: ''
    }
    this.getCookie = this.getCookie.bind(this);
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  submitForm(e){
    e.preventDefault();
    this.props.addPollMutation({
      variables: {
        userId: this.getCookie('uidm')
      }
    });
  }
  render() {
    return (
      <>
        <Form onSubmit={this.submitForm.bind(this)} >
          {/*<FormGroup>
            <Label>Book Name</Label>
            <Input type="text" onChange={ (e) => this.setState({ name: e.target.value })} />
          </FormGroup>
          <FormGroup>
            <Label>Genre</Label>
            <Input type="text" onChange={ (e) => this.setState({ genre: e.target.value })} />
          </FormGroup>
          <FormGroup>
            <Label>Author</Label>
            <Input type="select" onChange={ (e) => this.setState({ authorId: e.target.value })}>
              <option>Select author..</option>
              {this.displayAuthors()}
            </Input>
          </FormGroup>*/}
          <Button>New Poll</Button>
        </Form>
      </>
    );
  }
}

export default compose(
  graphql(addPollMutation, {name: "addPollMutation"})
)
(AddPoll);
