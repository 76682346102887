import React, {Component} from "react";
import { gql } from "apollo-boost";
import { graphql } from "react-apollo";
import {
  Media
} from "reactstrap";
import DeletePoll from "components/GraphQL/DeletePoll.jsx";

//components
//import BookDetails from "components/GraphQL/BookDetails.jsx";

/*
const getUserQuery = gql`
  query ($kajabiID: String){
    user (kajabiID: $kajabiID){
      userName
      id
    }
  }
`
*/

const getPollsQuery = gql`
  query ($id: String){
    polls (userId: $id){
      name
      id
      domain
      displayName
      theme
      privacyPolicy
      pollToolID
      websiteName
      offerTarget
      autoRedirect
      redirectTime
      landTopText
      landHeadline
      landSubhead
      landImage
      landYes
      landNo
      landSocialBefore
      landSocialAfter
      emailHead
      emailButton
      emailSubHead
      emailSLAPI
      emailSLHash
      emailSLList
      loadHeadline
      loadSubhead
      loadWaitMessage
      loadOfferHeadline
      loadImage
      loadOfferButton
      loadOfferLink
    }
  }
`
/*
<tr>
  <th scope="row">
    <Media className="align-items-center">
      <a
        className="avatar rounded-circle mr-3"
        href="#pablo"
        onClick={e => e.preventDefault()}
      >
        <img
          alt="..."
          src={require("assets/img/theme/poll.jpg")}
        />
      </a>
      <Media>
        <span className="mb-0 text-sm">
          Example Poll
        </span>
      </Media>
    </Media>
  </th>
  <td>testdomain.com</td>
  <td>fsttrks</td>
  <td><a href="#">testdomain.com/fsttrks</a></td>
  <td>
    <i className="fas fa-edit mr-2" />
    <i className="fas fa-trash mr-2" />
    <i className="fas fa-clone" />
  </td>
</tr>
*/

/*<li key={ poll.id } onClick={(e) => {this.setState({selected: poll.id})}}>{ poll.name }</li>*/

class PollList extends Component {
  constructor(props){
    super(props);
    this.state = {
      selected: null
    }
  }
  editPoll(
    name,
    id,
    domain,
    displayName,
    theme,
    privacyPolicy,
    pollToolID,
    websiteName,
    landTopText,
    landHeadline,
    landSubhead,
    landImage,
    landYes,
    landNo,
    landSocialBefore,
    landSocialAfter,
    emailHead,
    emailButton,
    emailSubHead,
    emailSLAPI,
    emailSLHash,
    emailSLList,
    loadHeadline,
    loadSubhead,
    loadWaitMessage,
    loadOfferHeadline,
    loadImage,
    loadOfferButton,
    loadOfferLink,
    redirectTime
  ){
    console.log('edit poll');
    console.log('Load Offer Link: ' + loadOfferLink);
    this.props.selectPoll(
      name,
      id,
      domain,
      displayName,
      theme,
      privacyPolicy,
      pollToolID,
      websiteName,
      landTopText,
      landHeadline,
      landSubhead,
      landImage,
      landYes,
      landNo,
      landSocialBefore,
      landSocialAfter,
      emailHead,
      emailButton,
      emailSubHead,
      emailSLAPI,
      emailSLHash,
      emailSLList,
      loadHeadline,
      loadSubhead,
      loadWaitMessage,
      loadOfferHeadline,
      loadImage,
      loadOfferButton,
      loadOfferLink,
      redirectTime,
    );
  }
  displayPolls(){
    var data = this.props.data;
    if(data.loading){
      return(<tr><td>Loading polls...</td></tr>);
    } else {
      data.startPolling(1500);
      if (data.polls !== undefined) {
        return data.polls.map(poll => {
          //console.log('poll list: ', poll);
          return(
            <tr key={ poll.id }>
              <th scope="row">
                <Media className="align-items-center">
                  <a
                    className="avatar rounded-circle mr-3"
                    href="#ftf"
                    onClick={() => this.editPoll(
                      poll.name,
                      poll.id,
                      poll.domain,
                      poll.displayName,
                      poll.theme,
                      poll.privacyPolicy,
                      poll.pollToolID,
                      poll.websiteName,
                      poll.landTopText,
                      poll.landHeadline,
                      poll.landSubhead,
                      poll.landImage,
                      poll.landYes,
                      poll.landNo,
                      poll.landSocialBefore,
                      poll.landSocialAfter,
                      poll.emailHead,
                      poll.emailButton,
                      poll.emailSubHead,
                      poll.emailSLAPI,
                      poll.emailSLHash,
                      poll.emailSLList,
                      poll.loadHeadline,
                      poll.loadSubhead,
                      poll.loadWaitMessage,
                      poll.loadOfferHeadline,
                      poll.loadImage,
                      poll.loadOfferButton,
                      poll.loadOfferLink,
                      poll.redirectTime
                    )}
                  >
                    <img
                      alt="..."
                      src={require("assets/img/theme/poll.jpg")}
                    />
                  </a>
                  <Media>
                    <span
                      className="mb-0 text-sm"
                      onClick={() => this.editPoll(
                        poll.name,
                        poll.id,
                        poll.domain,
                        poll.displayName,
                        poll.theme,
                        poll.privacyPolicy,
                        poll.pollToolID,
                        poll.websiteName,
                        poll.landTopText,
                        poll.landHeadline,
                        poll.landSubhead,
                        poll.landImage,
                        poll.landYes,
                        poll.landNo,
                        poll.landSocialBefore,
                        poll.landSocialAfter,
                        poll.emailHead,
                        poll.emailButton,
                        poll.emailSubHead,
                        poll.emailSLAPI,
                        poll.emailSLHash,
                        poll.emailSLList,
                        poll.loadHeadline,
                        poll.loadSubhead,
                        poll.loadWaitMessage,
                        poll.loadOfferHeadline,
                        poll.loadImage,
                        poll.loadOfferButton,
                        poll.loadOfferLink,
                        poll.redirectTime
                      )}
                      style={{cursor: "pointer"}}
                    >
                      {poll.name}
                    </span>
                  </Media>
                </Media>
              </th>
              <td>{poll.domain}</td>
              <td>{poll.displayName}</td>
              <td><a href={"https://" + poll.domain + "/?id=" + poll.id} target="_blank" rel="noopener noreferrer">{poll.domain}/?id={poll.id}</a></td>
              <td>
                <a href={"https://ftfunnels.thefasttracks.com/?id=" + poll.id} target="_blank" rel="noopener noreferrer">
                  <i
                    className="fas fa-external-link-square-alt mr-2 text-info"
                    style={{cursor: "pointer"}}
                  />
                </a>
                <i
                  className="fas fa-edit mr-2 text-info"
                  onClick={() => this.editPoll(
                    poll.name,
                    poll.id,
                    poll.domain,
                    poll.displayName,
                    poll.theme,
                    poll.privacyPolicy,
                    poll.pollToolID,
                    poll.websiteName,
                    poll.landTopText,
                    poll.landHeadline,
                    poll.landSubhead,
                    poll.landImage,
                    poll.landYes,
                    poll.landNo,
                    poll.landSocialBefore,
                    poll.landSocialAfter,
                    poll.emailHead,
                    poll.emailButton,
                    poll.emailSubHead,
                    poll.emailSLAPI,
                    poll.emailSLHash,
                    poll.emailSLList,
                    poll.loadHeadline,
                    poll.loadSubhead,
                    poll.loadWaitMessage,
                    poll.loadOfferHeadline,
                    poll.loadImage,
                    poll.loadOfferButton,
                    poll.loadOfferLink,
                    poll.redirectTime
                  )}
                  style={{cursor: "pointer"}}
                />
                <DeletePoll
                  id={poll.id}
                  selectPoll={this.props.selectPoll}
                />
                {/*<i className="fas fa-clone" />*/}
              </td>
            </tr>
          );
        });
      }
      return (<h4 className="ml-3 mt-3">No polls</h4>);
    }
  }
  render() {
    return (
      <>
          { this.displayPolls() }
      </>
    );
  }
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default graphql (getPollsQuery, {
  options: (props) => {
    return {
      variables: {
        id: getCookie('uidm')
      }
    }
  }
})(PollList);
