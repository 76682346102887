import React, {Component} from "react";
import { gql } from "apollo-boost";
import { graphql } from "react-apollo";
import { compose } from "recompose";

const deletePollMutation = gql`
  mutation ($id: ID!){
    deletePoll (id: $id){
      id
    }
  }
`

class DeletePoll extends Component {
  handleClick(e){
    console.log(this.props.id);
    e.preventDefault();
    let confirmation = window.confirm('Are you sure you want to delete this poll? This action cannot be undone!');
    if (confirmation) {
      this.props.selectPoll(
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        ''
      )
      this.props.deletePollMutation({
        variables: {
          id: this.props.id
        }
      });
    }
  }
  render() {
    return (
      <>
        <i
          className="fas fa-trash mr-2 text-warning"
          onClick={this.handleClick.bind(this)}
          style={{cursor: "pointer"}}
        />
      </>
    );
  }
}

export default compose(
  graphql(deletePollMutation, {
    name: "deletePollMutation",
    options: (props) => {
      return {
        variables: {
          id: props.id
        }
      }
    }
  })
)
(DeletePoll);
